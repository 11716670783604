//-------------
// Refunds Page
//-------------

// PACKAGES
import React from "react"

// COMPONENTS
import Sidebar from "../layout/Terms"

// COMPONENT BUILD
class RefundsPage extends React.Component {
    
    // JSX TEMPLATE
    render() {
        return (
            <Sidebar>
                <article className="px-4 py-5 shadow col-lg-10 p-md-6">
                    <h1 className="mb-4">Return And Refund Policy</h1>
                    <p>Thanks for shopping at Informeasy. </p>
                    <p>
                        If you are not entirely satisfied with your purchase,
                        we’re here to help.
                    </p>
                    <p>Please read this policy carefully.</p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Returns
                    </h3>
                    <p>
                        1.1. Physical products: Informeasy does not accept any
                        returns on personalized items.
                    </p>
                    <p>
                        1.2. Digital products: You do not need to return your
                        digital product. However, after the refund has been
                        issued the item will no longer be available online.
                    </p>
                    <p>
                        <u>If the product has not been shipped</u>
                    </p>
                    <p>
                        1.1. Before the order is complete: You may claim a full
                        refund anytime before you have fully completed your
                        order. Once you have submitted all the information about
                        your home, your order is considered completed.
                    </p>
                    <p>
                        1.2. After the order is complete: Once you have
                        completed your order you have 3 calendar days to claim a
                        full refund. After that only printing costs will be
                        returned.
                    </p>
                    <p>
                        1.3. Incomplete order:  If you haven’t completed your
                        order for 30 days you will automatically be refunded the
                        full amount.
                    </p>
                    <p>
                        <u>If the product has been shipped</u>
                    </p>
                    <p>
                        2.1. Before the product has been received: No refund
                        will be issued while the product is on its way.
                    </p>
                    <p>
                        2.2. After the product has been received: If the product
                        has been received you have 3 calendar days to contact us
                        for a replacement or refund. We honour requests for the
                        refund on the following reasons:
                    </p>
                    <p>
                        2.2.1. Major mistakes: Although all the products are
                        thoroughly reviewed before shipping, mistakes may occur.
                        If a defect is verified, a replacement will be offered
                        or a refund will be issued.
                    </p>
                    <p>
                        2.2.2. Product not-as-described: For the refund or
                        replacement request to be accepted clear evidence must
                        be provided proving that the purchased product is not as
                        it is described on the product description page.
                        Complaints which are based merely on false expectations
                        or wishes unfortunately will not be honoured.
                    </p>
                    <p>
                        2.2.3. Product arrived damaged: If the product arrived
                        damaged you have 3 calendar days to contact us for a
                        replacement or refund. Clear evidence must be provided.
                    </p>
                    <p>
                        2.3. Non-delivery of the product: If you have completed
                        your order and haven’t received it within 30 days a
                        replacement will be offered or a refund will be issued.
                    </p>
                    <p>
                        2.4. We have no obligation to provide a refund on the
                        following reasons:
                    </p>
                    <p>
                        2.4.1. Your product has suffered any damage caused by
                        regular or irregular use.
                    </p>
                    <p>
                        2.4.2. You have made a grammatical error or a mistake of
                        any kind or forgotten to add any detail through the form
                        filling process.
                    </p>
                    <p>
                        2.4.3. You have changed your mind about the product or
                        service.
                    </p>
                    <p>2.4.4. You ordered the product or service by mistake.</p>
                    <p>2.4.5. You ask for goodwill.</p>
                    <p>
                        After the product has been shipped the costs of printing
                        service are non-refundable. If you are entitled to a
                        refund, the cost of printing will be deducted from the
                        amount refunded.
                    </p>
                    <p>
                        Every request should be accompanied with detailed and
                        grounded reasons why you apply for a refund. Please make
                        sure your request does not contradict our Terms of
                        Service.
                    </p>
                    <p>
                        If your claim is accepted, we will initiate a refund to
                        the original method of payment. You will receive the
                        credit within a certain amount of days, depending on the
                        payment provider’s policies.
                    </p>
                    <p>
                        If you haven’t received a refund yet, start by checking
                        your bank account again. Then contact your credit card
                        company as it may take some time before your refund is
                        officially posted. Next contact your bank. There is
                        often some processing time before a refund is posted. If
                        you’ve done all of this and you still have not received
                        your refund yet, please contact us at
                        refund@informeasy.com.
                    </p>
                    <p>
                        <u>Promotions</u>
                    </p>
                    <p>Promotional products are non-refundable.</p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        How to claim a refund
                    </h3>
                    <p>
                        To ask for refund send us an email to
                        refund@informeasy.com.
                    </p>
                </article>
            </Sidebar>
        )
    }
}

export default RefundsPage
