//--------------
//  Terms Layout
//--------------

// PACKAGES
import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

// COMPONENTS
import HeaderTerms from "../components/organisms/header/Header-terms"

// COMPONENT BUILD
const Sidebar = props => {
    // JSX TEMPLATE
    return (
        <div>
            <Helmet defer={false} defaultTitle='Informeasy | House manual booklet for vacation rental'>
                <html lang='en' />
                <link rel="canonical" href='https://www.informeasy.com/' />
                <meta name="viewport" content="initial-scale=1, width=device-width"/>
                <noscript>Please eneable Javascript or use a modern web browser!</noscript>
                <meta name="description" content='Need more than just a free template? Improve guest experience with a custom made printed house manual booklet that provides information about your property, the area, house rules, safety and recommendations. No monthly instalments. Get your house manual designed and delivered to your doorstep.'></meta>
            </Helmet> 
            <HeaderTerms />
            <section className="pt-9 pb-5 pt-md-10 pb-md-7">
                <div className="container px-4">
                    <div className="row">
                        <nav className="col-sm-2 mb-3">
                            <ul className="list-inline">
                                <li>
                                    <Link className="text-dark" to="/terms">
                                        Terms
                                    </Link>
                                </li>
                                <li>
                                    <Link className="text-dark" to="/privacy">
                                        Privacy
                                    </Link>
                                </li>
                                <li>
                                    <Link className="text-dark" to="/cookies">
                                        Cookies
                                    </Link>
                                </li>
                                <li>
                                    <Link className="text-dark" to="/refunds">
                                        Refunds
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        {props.children}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Sidebar
