//------------------------
// Header-terms Component
//------------------------

// PACKAGES
import React from "react"
import { Link } from "gatsby"

// COMPONENTS
import Logo from "../../../assets/logos/ie-base-code.svg"
import MenuBack from "../../../assets/icons/menu-back.svg"

// COMPONENT BUILD
const Navbar2 = () => {
    
    // JSX TEMPLATE
    return (
        <header>
            <nav className="px-4 py-2 bg-white navbar fixed-top navbar-expand-md navbar-light border-bottom border-ie-lighter py-md-3">
                <div className="container">
                    <Link to="/">
                    <img className="logo-size" src={Logo} alt="Informeasy" />
                    </Link>
                    <button
                        className="px-2 py-1 border-0 navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navigations-08"
                        aria-controls="navigations-08"
                        aria-expanded="false"
                        aria-label="Toggle menu"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navigations-08"
                    >
                        <ul className="ml-auto navbar-nav">
                            <li className="nav-item">
                                <Link className="py-6 nav-link menu-link mr-md-5 py-md-2"
                                    to="/"
                                >
                                    <img className="pb-1 mr-3 d-inline-block position-relative" src={MenuBack} alt="Arrow-icon"/>
                                    Back to homepage
                                </Link>
                                <li className="nav-item">
                            </li>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar2
